'use client'

import { atom, useAtom, useAtomValue } from 'jotai'
import Dialog, { DialogClose } from '@/components/dialog'
import IconClose from '@haiper/icons-svg/icons/outline/crossed-large.svg'
import { auth0SignInAtom, readedNewFeatureKeysAtom } from '@/atoms'
import { cls, getLocalStorage, setLocalStorage, whisper } from '@/utils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CACHE_KEY_NEW_FEATURE_DIALOG } from '@/constants'
import useAmplitude from '@/hooks/useAmplitude'
import { uniq } from 'lodash-es'
import { usePathname } from 'next/navigation'
import Video, { VideoApi } from '@/components/video'
import Button from '@/components/button'
import Link from '@/components/link'
import IconArrowLeft from '@haiper/icons-svg/icons/outline/arrow-left.svg'
import IconArrowRight from '@haiper/icons-svg/icons/outline/arrow-right.svg'
import { useCachedAllTemplates } from '@/hooks/useTemplates'
import { Template } from '@/types'
import { useInView } from 'react-intersection-observer'
import useTemplateOfTheDay from '@/hooks/useTemplateOfTheDay'

export const templateDialogVisibleAtom = atom(false)

export default function TemplateOfTheDayDialog() {
  const [readedNewFeatureKeys, setReadedNewFeatureKeys] = useAtom(readedNewFeatureKeysAtom)
  const isLogin = useAtomValue(auth0SignInAtom)
  const { track } = useAmplitude()
  const { data: templates } = useCachedAllTemplates()
  const { data: templateOfTheDay } = useTemplateOfTheDay()
  const popupId = templateOfTheDay?.popup_id ?? ''
  const carouselTemplates = useMemo(() => {
    return templateOfTheDay?.templates ?? []
  }, [templateOfTheDay])

  const [templateDialogVisible, setTemplateDialogVisiblb] = useAtom(templateDialogVisibleAtom)

  useEffect(() => {
    if (popupId) {
      setTemplateDialogVisiblb(!getLocalStorage(CACHE_KEY_NEW_FEATURE_DIALOG)?.includes(popupId))
    }
  }, [setTemplateDialogVisiblb, popupId])

  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null)

  useEffect(() => {
    setSelectedTemplateId((old) => old ?? carouselTemplates?.[0]?.template_id)
  }, [carouselTemplates])

  const template: Template | null = useMemo(() => {
    return templates?.records?.find((t: Template) => t.template_id === selectedTemplateId) ?? null
  }, [templates, selectedTemplateId])

  const videoUrl = template?.examples?.[0]?.media_url ?? ''
  const thumbnailUrl = template?.examples?.[0]?.thumbnail_url ?? ''

  const { ref: contentRef, inView } = useInView()

  const videoApiRef = useRef<VideoApi | null>(null)

  const read = useCallback(() => {
    const newKeys = uniq([...readedNewFeatureKeys, popupId])
    setReadedNewFeatureKeys(newKeys)
    setLocalStorage(CACHE_KEY_NEW_FEATURE_DIALOG, newKeys)
    setTemplateDialogVisiblb(false)
  }, [readedNewFeatureKeys, setReadedNewFeatureKeys, setTemplateDialogVisiblb, popupId])

  const handleClose = useCallback(() => {
    read()
  }, [read])

  const viewEventTrackedRef = useRef(false)

  const pathname = usePathname()

  const realOpen =
    templateDialogVisible &&
    isLogin &&
    !pathname.startsWith('/auth') &&
    !pathname.startsWith('/onboarding') &&
    !!templateOfTheDay &&
    !!template

  useEffect(() => {
    if (realOpen && !viewEventTrackedRef.current) {
      track('view:template-of-the-day')
      viewEventTrackedRef.current = true
    }
  }, [realOpen, track])

  const startCreating = useCallback(() => {
    track('click:template-of-the-day:start-creating')
    read()
  }, [track, read])

  const onCloseButtonClick = useCallback(() => {
    read()
    track('click:template-of-the-day:close')
  }, [track, read])

  const autoTurnedOnSoundRef = useRef(false)

  const tryTurnOnSound = useCallback(() => {
    if (videoApiRef.current && !autoTurnedOnSoundRef.current) {
      const api = videoApiRef.current
      const video = videoApiRef.current?.video

      if (video && !video?.paused) {
        api?.unmute()
        autoTurnedOnSoundRef.current = true
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('click', tryTurnOnSound)

    return () => {
      window.removeEventListener('click', tryTurnOnSound)
    }
  }, [tryTurnOnSound])

  const scrollTo = useCallback(
    (index: number) => {
      setSelectedTemplateId(carouselTemplates[index].template_id)
    },
    [carouselTemplates],
  )

  const scrollPrev = useCallback(() => {
    const activeIndex = carouselTemplates.findIndex((t) => t.template_id === selectedTemplateId)
    if (activeIndex > 0) {
      const newIndex = Math.max(activeIndex - 1, 0)
      scrollTo(newIndex)
    } else if (activeIndex === 0) {
      const newIndex = Math.max(0, (carouselTemplates?.length ?? 0) - 1)
      scrollTo(newIndex)
    }
  }, [scrollTo, selectedTemplateId, carouselTemplates])

  const scrollNext = useCallback(() => {
    const activeIndex = carouselTemplates.findIndex((t) => t.template_id === selectedTemplateId)
    if (activeIndex < (carouselTemplates?.length || 0) - 1) {
      const newIndex = Math.min(activeIndex + 1, (carouselTemplates?.length || 0) - 1)
      scrollTo(newIndex)
    } else if (activeIndex === (carouselTemplates?.length || 0) - 1) {
      scrollTo(0)
    }
  }, [scrollTo, selectedTemplateId, carouselTemplates])

  return (
    <Dialog
      open={realOpen}
      titleClassName='hidden'
      // className='absolute md:relative inset-0 md:inset-none md:w-[578px] md:max-w-none p-4 md:p-6 overflow-x-hidden'
      className={cls(
        'relative gap-0 w-full md:w-[587px] max-w-full p-0 border-none border-0 translate-y-0 max-h-[100vh-100px] overflow-y-hidden',
      )}
      overlayClassName='pb-0'
      closeClassName='hidden'
      footerClassName='mt-[30px]'
      footer={null}
      onOpenChange={handleClose}
    >
      <div ref={contentRef} className='w-full h-full flex flex-col md:flex-row border p-4 md:p-6 md:rounded-lg'>
        <div className='w-full h-full flex flex-col justify-between gap-4'>
          <div className='w-full '>
            <div className='flex flex-col gap-6 pb-0'>
              <div className='w-full aspect-video'>
                {inView && (
                  <Video
                    // key={selectedTemplateId}
                    muted
                    autoPlay
                    loop
                    controls
                    hasAudioTrack
                    preload='auto'
                    className='cursor-pointer rounded-md border border-border aspect-video w-full'
                    roundedClassName='rounded-none'
                    src={videoUrl}
                    // poster={thumbnailUrl}
                    api={videoApiRef}
                  />
                )}
              </div>
              <div className='flex justify-between gap-4 items-center mb-6'>
                <div className='flex items-center gap-1 text-text font-bold text-heading-2xl h-14 tracking-32'>
                  Template of the day: {template?.name}
                </div>
                <DialogClose asChild autoFocus={false}>
                  <Button autoFocus={false} variant='transparent' onClick={onCloseButtonClick}>
                    <IconClose className='size-6 text-icon z-10' />
                  </Button>
                </DialogClose>
              </div>
            </div>
            <div className='w-full text-text flex flex-col text-body-md tracking-15 [&_strong]:text-heading-md [&_strong]:tracking-32 [&_strong]:font-bold'>
              {/* <span>{selectedCarouselTemplate?.description ?? ''}</span> */}
              {/* <br /> */}
              <span>
                💖 We reward <strong>15</strong> winners with <strong>3,000</strong> credits every week.
              </span>
              <br />
              <span>
                Just post your video, tag us (<strong>@HaiperGenAI</strong> on X, <strong>@Haiper.ai</strong> on TikTok
                & Instagram), and you could win!
              </span>
            </div>
          </div>
          <div className='flex flex-col gap-3'>
            <div className='w-full h-10 '>
              <Button variant='primary' className='w-full rounded-md shadow-sm' onClick={startCreating}>
                <Link prefetch href={`/template/${selectedTemplateId}`} className='w-full'>
                  Start creating
                </Link>
              </Button>
            </div>
            <div className='w-full h-10 flex justify-center items-center gap-6'>
              <Button
                variant='outline'
                className='size-10 p-0'
                // disabled={activeIndex === 0}
                onClick={scrollPrev}
              >
                <IconArrowLeft className='size-6 text-icon' />
              </Button>
              <div className='flex gap-2 h-3 items-center'>
                {carouselTemplates?.map((item, index) => (
                  <i
                    key={index}
                    className={cls(
                      'block size-2 rounded-full bg-icon-disable cursor-pointer',
                      selectedTemplateId === item.template_id ? 'size-3 bg-icon-interactive' : '',
                    )}
                    onClick={() => scrollTo(index)}
                  />
                ))}
              </div>
              <Button
                variant='outline'
                className='size-10 p-0'
                // disabled={activeIndex === (dataSource?.length || 0) - 1}
                onClick={scrollNext}
              >
                <IconArrowRight className='size-6 text-icon' />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

import useMarketingEvents from './useMarketingEvents'
import { whisper } from '@/utils'

const useTemplateOfTheDay = () => {
  const { data: marketingEvents, ...others } = useMarketingEvents()
  whisper('marketingEvents is: ', marketingEvents)

  const templateOfToday = marketingEvents?.find((event) => event.id === 'template_of_the_day')
  const config: haiper.TemplateOfTheDayConfig | null = (templateOfToday?.action?.payload as any) ?? null

  return {
    ...others,
    data: config,
    event: templateOfToday ?? null,
  }
}

export default useTemplateOfTheDay
